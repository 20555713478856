/* GLOBAL VARIABLES */
$pfizer-font-family: 'Open Sans', sans-serif;

$text-color: #606060;
$link-color: #0093cf;
$white-text-color: #FFFFFF;
$pfizer-light-gray: #e8e8e8;
$pfizer-light-green: #7dbb02;
$pfizer-dark-green: #01a951;
$pfizer-light-blue: #80d6f7;
$pfizer-dark-blue: #0093cf;
$pfizer-light-pink: #ff7ebf;
$pfizer-dark-pink: #d61272;
$pfizer-purple: #4a245e;
$pfizer-orange: #f26649;

$highlight: #deff9c;

$old-header-background-color: #e1f3ff;
$header-background-color: #fafafa;
$menu-background-color: #0093cf;
$paging-background-color: #FFFFFF;
$unfocused-background-color: none;
$new-members-background-color: #f3f8e4;
$anniversary-background-color: #ffecf7;
$birthday-background-color: #def5fd;
$orange-background: #ffbfb1;
$translucent-white-color: rgba(255,255,255,0.7);
$nav-selection-color: rgba(255, 255, 255, 0.5);
$slider-bkgd: rgba(255, 255, 255, 0.85);
$black-background: #000000;
$black-shadow: rgba(0,0,0,.5);

$no-border: none;
$solid-border: 1px solid #606060;
$solid-light-border: 1px solid #E9E9E9;
$dotted-border: 1px dotted #606060;

$full-width: 100%;
$header-width: 100%;
$slider-text-width: 40%;
$half-width: 50%;
$side-nav-width: 25%;

$center-margin: 0 auto;

$float-left: left;
$float-right: right;
$float-none: none;

$break-large: 1200px;
$break-medium: 768px;
$break-small: 640px;
$break-xsmall: 360px;