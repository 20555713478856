.white { color: $white-text-color; }
.orange { color: $pfizer-orange; }
.lightblue { color: $pfizer-light-blue; }
.darkblue { color: $pfizer-dark-blue; }
.lightpink { color: $pfizer-light-pink; }
.darkpink { color: $pfizer-dark-pink; }
.lightgreen { color: $pfizer-light-green; }
.darkgreen { color: $pfizer-dark-green; }
.purple { color: $pfizer-purple; }

.whiteBKGD { background: $white-text-color; }
.orangeBKGD { background: $pfizer-orange; }
.lightblueBKGD { background: $pfizer-light-blue; }
.darkblueBKGD { background: $pfizer-dark-blue; }
.lightpinkBKGD { background: $pfizer-light-pink; }
.darkpinkBKGD { background: $pfizer-dark-pink; }
.lightgreenBKGD { background: $pfizer-light-green; }
.darkgreenBKGD { background: $pfizer-dark-green; }
.purpleBKGD { background: $pfizer-purple; }